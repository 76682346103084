import DownloadTable from "./DownloadTable";
import { connect } from "react-redux";
import * as appsActions from "../../redux/actions/AppsActions";
import { bindActionCreators } from "redux";
import Spinner from "../common/Spinner.js";

function DownloadPage({ actions, apps }) {
  if (apps.length === 0) {
    actions.loadApps();
    return <Spinner />;
  }
  return <DownloadTable apps={apps ? apps : []} />;
}

//moze krace na tri nacina
function mapStateToProps(state) {
  return {
    apps: state.apps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(appsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPage);
