import { useState, useEffect } from "react";

const GetApps = () => {
  const [apps, setApps] = useState([]);

  useEffect(() => {
    (async () => {
      setApps(await getApps());
    })();
  }, []);

  async function getApps() {
    try {
      const response = await fetch(process.env.REACT_APP_API + "GetAllData");
      const payload = await response.json();
      return payload;
    } catch (error) {
      return [];
    }
  }

  return apps;
};

export default GetApps;
