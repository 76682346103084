import GetUser from "../../components/common/GetUser";
import ValidateUser from "../../components/common/ValidateUser";
import * as types from "./actionTypes";

export function loadUserSucceeded(user) {
  return { type: types.LOAD_USER_SUCCESS, user };
}

export function loadUser() {
  return function (dispatch) {
    const user = GetUser();
    if (user) {
      if (user.userRoles.length > 0) {
        return dispatch(loadUserSucceeded(user));
      }
    }
  };
}

export function validatedUserSucceeded(user) {
  return { type: types.VALIDATE_USER_SUCCESS, user };
}

export function validateUser(user) {
  return function (dispatch) {
    const validated = ValidateUser(user.userRoles);
    const newUser = { ...user };
    newUser.validated = validated;
    if (validated) return dispatch(validatedUserSucceeded(newUser));
  };
}
