import { toast } from "react-toastify";

const ValidateUser = (props) => {
  if (typeof props !== "undefined") {
    const isValidated = props.includes("mettlermarbo");
    isValidated
      ? toast.success("You have successfully logged in.")
      : toast.error("Login Failed");
    return isValidated;
  } else {
    return false;
  }
};

export default ValidateUser;
