import { useState, useEffect } from "react";

const GetDocuments = () => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    (async () => {
      setDocuments(await getDocuments());
    })();
  }, []);

  async function getDocuments() {
    try {
      const response = await fetch(
        process.env.REACT_APP_API + "GetAllDocuments"
      );
      const payload = await response.json();
      return payload;
    } catch (error) {
      return [];
    }
  }

  return documents;
};

export default GetDocuments;
