import { useState, useEffect } from "react";

const GetUser = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    (async () => {
      setUser(await getUser());
    })();
  }, []);

  async function getUser() {
    try {
      const response = await fetch("/.auth/me");
      const payload = await response.json();
      const { clientPrincipal } = payload;
      const newUser = {
        userRoles: clientPrincipal.userRoles,
        validated: false,
      };
      return newUser;
    } catch (error) {
      console.error("e");
      const newUser = {
        userRoles: [],
        validated: false,
      };
      return newUser;
    }
  }

  return user;
};

export default GetUser;
