import { React } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import HomeText from "../../text/HomeText";
import FormulaList from "../../images/FormulaMeasurementList.jpg";

const HomePage = () => {
  return (
    <div>
      <Grid container spacing={2} className="app-grid">
        <Grid item xs={12} sm={6}>
          <Card className="app-text" style={{ backgroundColor: "#ffffff" }}>
            {HomeText.split("\n").map((str) => (
              <p key={str}>{str}</p>
            ))}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className="app-card" style={{ backgroundColor: "#ffffff" }}>
            <img alt="" src={FormulaList} className="app-home-image" />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
