import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../../images/logo_new_crop.svg";

const NavigationHeader = (props) => {
  return (
    <Navbar bg="light" expand="lg" className="border-bottom border-primary">
      <Container>
        <Navbar.Brand href="/">
          <img alt="" src={logo} className="app-logo" />
        </Navbar.Brand>
      </Container>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <NavLink className="p-2 bg-light app-link" to="/">
            Home
          </NavLink>

          {props.isValidated ? (
            <>
              <NavLink className="p-2 bg-light app-link" to="/download">
                Download
              </NavLink>
              <NavLink className="p-2 bg-light app-link" to="/documents">
                Documents
              </NavLink>
              <NavLink className="p-2 bg-light app-link" to="/contact">
                Contact
              </NavLink>
              <a className="p-2 bg-light app-link" href="/logout">
                Logout
              </a>
            </>
          ) : (
            <>
              <NavLink className="p-2 bg-light app-link" to="/contact">
                Contact
              </NavLink>
              <a className="p-2 bg-light app-link" href="/login">
                Login
              </a>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationHeader;
