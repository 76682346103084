import React from "react";
import NavigationHeader from "./NavigationHeader";
import * as userActions from "../../redux/actions/UserActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const Navigation = ({ user, actions }) => {
  if (user.userRoles.length > 0 && user.validated === false) {
    actions.validateUser(user);
  } else if (user.userRoles.length === 0) {
    actions.loadUser();
  }
  return <NavigationHeader isValidated={user.validated} />;
};

//moze krace na tri nacina
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
