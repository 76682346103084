import React from "react";
import { Table } from "react-bootstrap";
import { Button, ButtonToolbar } from "react-bootstrap";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const DocumentsTable = (props) => {
  return (
    <div>
      <Grid container spacing={2} className="app-grid">
        <Grid item xs={12}>
          <Card className="app-text" style={{ backgroundColor: "#ffffff" }}>
            <Table className="mt-4" striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {props.documents.map((document) => (
                  <tr key={document.id}>
                    <td>{document.name}</td>
                    <td>
                      <ButtonToolbar>
                        <a
                          href={
                            process.env.REACT_APP_API +
                            "GetDocument?name=" +
                            document.name
                          }
                        >
                          {
                            <Button className="mr-2" variant="info">
                              Download
                            </Button>
                          }
                        </a>
                      </ButtonToolbar>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default DocumentsTable;
