import GetApps from "../../components/download/GetApps";
import * as types from "./actionTypes";

export function loadAppsSucceeded(apps) {
  return { type: types.LOAD_APPS_SUCCESS, apps };
}

export function loadApps() {
  return function (dispatch) {
    const apps = GetApps();
    if (apps.length > 0) {
      return dispatch(loadAppsSucceeded(apps));
    }
  };
}
