import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function documentsReducer(
  state = initialState.documents,
  action
) {
  switch (action.type) {
    case types.LOAD_DOCUMENTS_SUCCESS:
      return action.documents;
    default:
      return state;
  }
}
