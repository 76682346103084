import DocumentsTable from "./DocumentsTable";
import { connect } from "react-redux";
import * as documentsActions from "../../redux/actions/DocumentsActions";
import { bindActionCreators } from "redux";
import Spinner from "../common/Spinner.js";

function DocumentsPage({ actions, documents }) {
  if (documents.length === 0) {
    actions.loadDocuments();
    return <Spinner />;
  }
  return <DocumentsTable documents={documents ? documents : []} />;
}

//moze krace na tri nacina
function mapStateToProps(state) {
  return {
    documents: state.documents,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(documentsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);
