import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ContactText from "../../text/ContactText";

const ContactPage = () => {
  return (
    <div>
      <Grid container spacing={2} className="app-grid">
        <Grid item xs={12}>
          <Card className="app-text">
            {ContactText.split("\n").map((str) => (
              <p>{str}</p>
            ))}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactPage;
