import { combineReducers } from "redux";
import apps from "./AppsReducer";
import user from "./UserReducer";
import documents from "./DocumentsReducer";
const rootReducer = combineReducers({
  apps,
  user,
  documents,
});

export default rootReducer;
