import "../scss/custom.scss";

import HomePage from "./home/HomePage";
import DownloadPage from "./download/DownloadPage";
import DocumentsPage from "./documents/DocumentsPage";
import Navigation from "./common/Navigation";
import { Route, Switch } from "react-router-dom";
import ContactPage from "./contact/ContactPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <Navigation />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/download" component={DownloadPage} />
        <Route exact path="/documents" component={DocumentsPage} />
        <Route exact path="/contact" component={ContactPage} />
      </Switch>
      <ToastContainer
        autoClose={2000}
        hideProgressBar
        position="bottom-right"
      />
    </div>
  );
}

export default App;
