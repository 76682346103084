import GetDocuments from "../../components/documents/GetDocuments";
import * as types from "./actionTypes";

export function loadDocumentsSucceeded(documents) {
  return { type: types.LOAD_DOCUMENTS_SUCCESS, documents };
}

export function loadDocuments() {
  return function (dispatch) {
    const documents = GetDocuments();
    if (documents.length > 0) {
      return dispatch(loadDocumentsSucceeded(documents));
    }
  };
}
